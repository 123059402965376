<script lang="ts" setup>
import { type HTMLAttributes } from 'vue';

const props = defineProps<{
  backgroundColor: string;
  class?: HTMLAttributes['class'];
  end: Date | string;
  timeBlocks?: boolean;
}>();

const INTERVAL_MILLISECONDS = 1000;

const id = useId();
const now = useState(`now-date-${id}`, () => new Date());

const delegatedProps = computed(() => {
  const {
    class: _,
    ...delegated
  } = props;

  return delegated;
});

const countdownTime = computed(() => {
  const { end } = delegatedProps.value;
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  const endDate = typeof end === 'string'
    ? new Date(end)
    : end;
  const diff = Math.max(endDate.getTime() - now.value.getTime(), 0);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  /* eslint-enable @typescript-eslint/no-magic-numbers */

  return {
    days,
    hours,
    minutes,
    seconds,
  };
});

const isDarkBackground = computed(() => isDarkColor(props.backgroundColor));

useIntervalFn(() => {
  if (now?.value) {
    now.value = new Date();
  }
}, INTERVAL_MILLISECONDS);
</script>

<template>
  <span
    :class="cn(
      'flex items-center rounded-md px-0.5 py-1 text-xs font-light tracking-wide md:gap-0.5',
      [isDarkBackground ? 'text-white' : 'text-dark'],
      props.class
    )"
    :style="{ backgroundColor: backgroundColor }"
  >
    <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
    <span
      class="flex h-4 items-center justify-center rounded-xs"
      :class="{
        'md:size-8 md:bg-grey-light md:px-0': timeBlocks
      }"
    >
      <span class="mr-px w-4 text-center font-semibold">{{ `${countdownTime.days}`.padStart(2, '0') }}</span>
      <span class="">d</span>
    </span>
    <span>:</span>
    <span
      class="flex h-4 items-center justify-center rounded-xs"
      :class="{
        'md:size-8 md:bg-grey-light md:px-0': timeBlocks
      }"
    >
      <span class="mr-px w-4 text-center font-semibold">{{ `${countdownTime.hours}`.padStart(2, '0') }}</span>
      <span class="">h</span>
    </span>
    <span>:</span>
    <span
      class="flex h-4 items-center justify-center rounded-xs"
      :class="{
        'md:size-8 md:bg-grey-light md:px-0': timeBlocks
      }"
    >
      <span class="mr-px w-4 text-center font-semibold">{{ `${countdownTime.minutes}`.padStart(2, '0') }}</span>
      <span class="">m</span>
    </span>
    <span>:</span>
    <span
      class="flex h-4 items-center justify-center rounded-xs"
      :class="{
        'md:size-8 md:bg-grey-light md:px-0': timeBlocks
      }"
    >
      <span class="mr-px w-4 text-center font-semibold">{{ `${countdownTime.seconds}`.padStart(2, '0') }}</span>
      <span class="">s</span>
    </span>
    <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
  </span>
</template>
